<template>
  <div>
    <div class="login-section" :class="$i18n.locale == 'en' ? 'en-style' : ''">
      <div class="uk-container">
        <div
          class="uk-child-width-1-1 uk-child-width-1-2@m uk-flex-middle"
          uk-grid
        >
          <div class="uk-form">
            <div class="form-border">
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="uk-form-stacked"
                @submit.prevent="login()"
              >
                <h4>{{ $t("form.login") }}</h4>
                <div class="uk-margin">
                  <div class="uk-form-controls">
                    <label>{{ $t("form.email") }}</label>
                    <v-text-field
                      v-model="email"
                      :rules="[
                        (v) => !!v || $t('required.email'),
                        (v) => /.+@.+/.test(v) || $t('required.validEmail')
                      ]"
                      :placeholder="$t('form.email')"
                      autocomplete="off"
                      autocorrect="off"
                    ></v-text-field>
                  </div>
                </div>
                <div class="uk-margin">
                  <label>{{ $t("form.password") }}</label>
                  <v-text-field
                    v-model="password"
                    :rules="[
                      (v) => !!v || $t('required.password'),
                      (v) => v.length >= 10 || $t('required.validPassword')
                    ]"
                    :placeholder="$t('form.password')"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="false"
                    type="password"
                  >
                  </v-text-field>
                </div>
                <!-- end input 1 -->
                <div class="uk-margin">
                  <base-button
                    @click="loginMethod()"
                    :options="{ block: true, isLoading: loading }"
                  >
                    {{ $t("form.login") }}
                  </base-button>
                  <router-link
                    :to="{ name: 'forgetPassword' }"
                    class="uk-btn-empty"
                    >{{ $t("form.forgetPassword") }} ؟
                  </router-link>
                </div>
                <hr />
                <div class="uk-margin uk-form-NewAcoount">
                  <h4>{{ $t("form.newUser") }} ؟</h4>
                  <router-link
                    :to="{ name: 'signUp' }"
                    :style="{ color: this.whiteColor }"
                    class=" uk-width-1-1 uk-margin-auto uk-margin uk-btn-green btn-primary-hover uk-display-block btn-signup"
                  >
                    {{ $t("form.signup") }}
                  </router-link>
                </div>
              </v-form>
            </div>
          </div>
          <div class="uk-img">
            <img :src="loginImg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "../../../core/Base/Buttons/BaseButton";
import { EventBus } from "../../../main";
import { mapActions } from "vuex";

function notification(group, type, title, duration) {
  return EventBus.$notify({
    group: group,
    type: type,
    title: title,
    duration: duration
  });
}

export default {
  name: "Login",
  components: { BaseButton },
  data: () => ({
    /**
     * @desc valid form value
     * @author karam mustafa
     * @task #BACT-101
     * */
    valid: true,
    /**
     * @author karam mustafa
     * @task #BACT-101
     * */
    loginImg: require("../../../assets/img/login.png"),
    /**
     * @author karam mustafa
     * @task #BACT-101
     * */
    password: "",
    /**
     * @author karam mustafa
     * @task #BACT-101
     * */
    email: "",
    /**
     * @author karam mustafa
     * @task #BACT-101
     * */
    type: "user",
    /**
     * @author karam mustafa
     * @task #BACT-101
     * */
    loading: false,
    /**
     * @author karam mustafa
     * @task #BACT-101
     * */
    passwordRules: [
      (v) => !!v || this.$t("required.password"),
      (v) => v.length >= 10 || this.$t("required.validPassword")
    ],
    /**
     * @author karam mustafa
     * @task #BACT-101
     * */
    emailRules: [
      (v) => !!v || this.$t("required.email"),
      (v) => /.+@.+/.test(v) || this.$t("required.valedEmail")
    ]
  }),
  methods: {
    ...mapActions("Auth", ["login"]),
    /**
     * @desc login function
     * @author karam mustafa
     * @task #BACT-101
     * */
    loginMethod() {
      if (!this.$refs.form.validate()) 
      return notification(
          "public",
          "error",
          this.$t("form.checkMissingField"),
          4000
        );
      // show loading snippets
      this.loading = true;
      this.login({
        password: this.password,
        email: this.email,
        type: this.type
      })
        .then(() => {
          this.loading = false;
          setTimeout(() => {
            this.$router.push({ name: "home" });
          }, 2000);
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },
  computed: {}
};
</script>

<style scoped>
.en-style {
  direction: ltr;
}
</style>
